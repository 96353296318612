import {Courses, COURSES_BUTTONS, COURSES_ROUTES} from '@entities/common/courses';
import {ITopNavBar} from '@entities/components/top-nav-bar/interfaces';
import {ANGULAR_COURSES} from '@entities/pages/angular-course/courses.constants';
import {DATA_CAMPINGS} from '@entities/pages/data-camping/courses.constants';
import {JS_COURSES} from '@entities/pages/js-course/courses.constants';
import {ERouting} from '@entities/routing/enums';
import {ContactUsHelper} from '@shared/helpers/contact-us.helper';

export const PAGES: ITopNavBar.MenuItem[] = [
	{
		title: 'Approach',
		link: '/',
		isVisible: true,
	},
	{
		title: 'Drone School',
		link: `/${COURSES_ROUTES[Courses.Drone]}`,
		highlighted: true,
		highlightedColor: COURSES_BUTTONS.colors[Courses.Drone],
		isVisible: false,
	},
	{
		title: 'DataMill',
		link: `/${COURSES_ROUTES[Courses.DataMill]}`,
		highlighted: true,
		highlightedColor: COURSES_BUTTONS.colors[Courses.DataMill],
		isVisible: true,
	},
	{
		title: `${Courses.DWH} ${ContactUsHelper.getCurrentCourse(DATA_CAMPINGS).startDate.getFullYear()}`,
		link: `/${COURSES_ROUTES[Courses.DWH]}`,
		highlighted: true,
		highlightedColor: COURSES_BUTTONS.colors[Courses.DWH],
		isVisible: !ContactUsHelper.isCourseStarted(DATA_CAMPINGS),
	},
	{
		title: `${Courses.JS} ${ContactUsHelper.getCurrentCourse(JS_COURSES).startDate.getFullYear()}`,
		link: `/${COURSES_ROUTES[Courses.JS]}`,
		highlighted: true,
		highlightedColor: COURSES_BUTTONS.colors[Courses.JS],
		isVisible: !ContactUsHelper.isCourseStarted(JS_COURSES),
	},
	{
		title: `${Courses.Angular} ${ContactUsHelper.getCurrentCourse(ANGULAR_COURSES).startDate.getFullYear()}`,
		link: `/${COURSES_ROUTES[Courses.Angular]}`,
		highlighted: true,
		highlightedColor: COURSES_BUTTONS.colors[Courses.Angular],
		isVisible: !ContactUsHelper.isCourseStarted(ANGULAR_COURSES),
	},
	{
		title: 'DWH Learning path',
		link: `/${ERouting.LazyModulePaths.DwhLearningPath}`,
		isVisible: true,
	},
	{
		title: 'JS Learning path',
		link: `/${ERouting.LazyModulePaths.JsLearningPath}`,
		isVisible: true,
	},
	{
		title: 'Our courses',
		link: '',
		isVisible: true,
		pages: [
			{
				title: `${Courses.DWH}`,
				subtitle: '',
				link: `/${COURSES_ROUTES[Courses.DWH]}`,
			},
			{
				title: `${Courses.JS}`,
				subtitle: '',
				link: `/${COURSES_ROUTES[Courses.JS]}`,
			},
			{
				title: `${Courses.Angular}`,
				subtitle: '',
				link: `/${COURSES_ROUTES[Courses.Angular]}`,
			},
		],
	},
	{
		title: 'We are hiring',
		link: `/${ERouting.LazyModulePaths.WeAreHiring}`,
		isVisible: true,
	},
];
